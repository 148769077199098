<template>
  <div>
    <inputText
      :data="btnTxt"
      :label="$t('design.button.title')"
      :callback="setTitle"
      :help="$t('design.button.info')"
      :error="getError()"
      :styling="{ input: 'width: 100%; max-width: 300px; display: block' }"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      btnTxt: this.getStartBtn()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getStartBtn() {
      if (this.$store.state.business.unsaved.startBtn) {
        return this.$store.state.business.unsaved.startBtn;
      } else {
        this.setTitle(this.$t("data.button"));
        return this.$t("data.button");
      }
    },
    setTitle(val) {
      this.btnTxt = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.startBtn = this.btnTxt;
      this.$store.commit("business/prefetch", data);
    },
    getError() {
      if (this.btnTxt || !this.$store.state.save.trySave) {
        return false;
      } else {
        return this.$t("design.button.error");
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
